import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Card from "../../components/card"
import AnchorLink from "../../components/anchorLink"

import ReourcesIllustration from "../../assets/illustrations/resources.svg"

import MenuBookIcon from "@mui/icons-material/MenuBook"
import VideocamIcon from "@mui/icons-material/Videocam"
import DescriptionIcon from "@mui/icons-material/Description"
import MicIcon from "@mui/icons-material/Mic"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"

const HeroSection = () => (
  <div className="lg:flex items-center py-16 gap-24">
    <div className="lg:flex-grow">
      <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">Resources</h1>
      <p className="text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
      Up-to-date collection of top articles, books, podcasts, videos and more to help you with your design journey. If you have any recommendations, please send us a tweet at <a href="https://twitter.com/uxchoice" target="_blank">@uxchoice</a> and I will add it
        to the list.
      </p>
      <a href="mailto:uxchoice@gmail.com" class="btn" target="_blank">Suggest a resource</a>
    </div>
    <div className="md:flex-shrink-0 mt-12 md:mt-0">
      <ReourcesIllustration className="mx-auto" />
    </div>
  </div>
)

const resources = [
  {
    name: "Books",
    Icon: MenuBookIcon,
    url: "/resources/books",
  },
  {
    name: "Videos",
    Icon: VideocamIcon,
    url: "/resources/videos",
  },
  {
    name: "Articles",
    Icon: DescriptionIcon,
    url: "/resources/articles",
  },
  {
    name: "Podcasts",
    Icon: MicIcon,
    url: "/resources/podcasts",
  },
  {
    name: "Events",
    Icon: CardGiftcardIcon,
    url: "/resources/events",
  },
]

const ResourcesSection = () => (
  <section className="py-10">
    <div className="flex flex-wrap gap-12 mb-12">
      {resources.map(resource => (
        <AnchorLink
          key={resource.name}
          to={resource.url}
          className="flex-grow flex-shrink-0"
        >
          <Card className="text-center whitespace-nowrap p-6">
            <resource.Icon
              fontSize=""
              className="mb-4 text-5xl text-blue-primary"
            />
            <div className="font-bold text-gray-700">{resource.name}</div>
          </Card>
        </AnchorLink>
      ))}
    </div>
  </section>
)

const ResourcesPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Best UI/UX design resources" description="Collection of top resources, articles, and books for UX and UI designers" />
      <main>
        <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
          <HeroSection />
          <ResourcesSection />
        </div>
      </main>
    </Layout>
  )
}

export default ResourcesPage
